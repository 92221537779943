<template>
  <div id="user-list">
    <!-- app drawer -->
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <user-form
        v-model="item"
        :is-form-active="isFormActive"
        :role-options="roleOptions"
        :plan-options="planOptions"
        @refetch-data="fetchUsers"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un utilisateur') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template #form-cancel>
          <v-btn
            :loading="loading"
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </user-form>
    </v-dialog>
    <!-- user total card -->
    <!--    <v-row class="mb-5">
      <v-col
        v-for="total in userTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}&#45;&#45;text`"
            >
              <v-icon
                size="25"
                :color="resolveUserTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveUserTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>-->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Utilisateurs") }} <v-spacer />
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click.stop="openDialog(false)"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>{{ $t("Ajouter un utilisateur") }}</span>
        </v-btn>
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table"
        fixed-header
        height="500"
        dense
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        hide-default-footer
        :show-select="false"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" />
        </template>
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :lazy-src="require(`@/assets/images/avatars/2.png`)"
                :src="item.avatar || require(`@/assets/images/avatars/2.png`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'user-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
              <!--              <small>@{{ item.username }}</small>-->
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{item}">
          <div
            v-if="item.role"
            class="d-flex align-center"
          >
            <!--            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role)}&#45;&#45;text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveUserRoleVariant(item.role.name)"
              >
                {{ resolveUserRoleIcon(item.role.name) }}
              </v-icon>
            </v-avatar>-->
            <span class="text-capitalize">{{ item.roles?item.roles.map(ele => ele.display_name).join(', '):'' }}</span>
          </div>
        </template>
        <!-- status -->
        <template #[`item.status`]="{item}">
          <!--          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}&#45;&#45;text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolveUserStatusName(item.status) }}
          </v-chip>-->
          <user-status-chip
            :object="item"
            @refetch-data="fetchUsers"
          />
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'user-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>{{ $t('Détails') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="openDialog(item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>{{ $t('Modifier') }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="confirmDelete(item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span><span>{{ $t('Supprimer') }}</span></span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            small
            color="primary"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import UserForm from './UserForm.vue'
import userStoreModule from '../userStoreModule'

import useUsersList from './useUsersList'
import UserStatusChip from '@/views/apps/user/user-list/UserStatusChip'
import RowsPerPage from '@/components/RowsPerPage'
import HeaderFilters from '@/components/HeaderFilters'

export default {
  components: {
    HeaderFilters,
    RowsPerPage,
    UserStatusChip,
    UserForm,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      item,
      items,
      tableColumns,
      searchQuery,
      roleFilter,
      filters,
      supervisorFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      userTotalLocal,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      deleteDialog,
      confirmDelete,
      openDialog,
      deleteItem,
      fetchUsers,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusName,
      resolveUserTotalIcon,
    } = useUsersList()

    const superiors = ref([])

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Author', value: 'author' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Actif', value: 1 },
      { title: 'Inactif', value: 0 },
    ]

    /* store.dispatch('app-user/searchUsers', { term: '', rowsPerPage: 100 }).then(response => {
      superiors.value = response.data
    }) */

    axios
      .get('/roles', { params: options })
      .then(response => {
        store.state['app-users'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'role')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))

    return {
      item,
      items,
      superiors,
      tableColumns,
      filters,
      searchQuery,
      roleFilter,
      supervisorFilter,
      statusFilter,
      totalItems,
      roleOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      userTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      deleteItem,
      confirmDelete,
      deleteDialog,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusName,
      resolveUserTotalIcon,
      fetchUsers,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
